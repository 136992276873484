import React, { Component } from "react";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../../services/api";

import {
  isAssinaturaValida,
  obterUsuarioLogado,
} from "../../../store/userSession";

import BuscaMapaImoveis from "../../Busca/MapaImoveis";

import InformationCard from "../../../components/UI/InformationCard";
import Footer from "../../../components/UI/Footer";
import Search from "../../../components/Search/Posts/Search";
// import AdvertisementImages from "../../../components/AdvertisementImages/AdvertisementImages";
import SearchPosts from "../../../components/SearchPosts/SearchPosts";

import { serveImage } from "../../../utils/serveImage";

import ImageDefault from "../../../assets/images/sample-image.jpg";

import "./index.scss";
import "../../../components/Navigation/NavigationItems/NavigationItems.scss";
import { FormControlLabel, Switch } from "@material-ui/core";

console.log(obterUsuarioLogado());

const markerColor = {
  'Edifícios Comerciais': '#dcb0f7',
  'Edifícios Residenciais': '#e9faa5',
  'Loteamentos Comerciais / Industriais': '#d1d1c7',
  'Loteamentos Fechados': '#fab0aa'
}

class BuscaVenal extends Component {
  classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    root2: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));

  addressSearch = false;
  imovelSelecionado = undefined;

  state = {
    mapVision: false,
    doFind: false,
    show: false,
    options: [],
    page: 1,
    count: 1,
    itensPerPage: 9,
    showInfoWindow: [],
    showInfoWindowUser: true,
    showWindowGroup: false,
    myCoordinates: {
      latitude:
        sessionStorage.getItem("latitude") !== undefined
          ? Number(sessionStorage.getItem("latitude"))
          : 0,
      longitude:
        sessionStorage.getItem("longitude") !== undefined
          ? Number(sessionStorage.getItem("longitude"))
          : 0,
    },
    favoritou: false,
    address: "",
    loadingAddress: false,
    position: null,
    id: null,
    posts: [],
    postsSearch: null,
    postsSelected: null,
    imovel: null,
    quadrinha: null,
    mostrarPainel: false,
    mostrarQuadrinha: false,
    loadingFavorito: false,
    scroll: false,
    cidade: window.localStorage.getItem("city")
      ? window.localStorage.getItem("city")
      : "",
    tipo: [],
    elevadores: "",
    lazer: [],
    itensDeEmpreendimentos: [
      "Edifícios Comerciais",
      "Edifícios Residenciais",
      "Loteamentos Comerciais / Industriais",
      "Loteamentos Fechados",
    ],
    itensDeLazer: [
      "Academia",
      "Bar/Lanchonete",
      "Bicicletário",
      "Bosque",
      "Brinquedoteca",
      "Campo de futebol",
      "Campo de futebol society",
      "Car wash",
      "Churrasqueira",
      "Cinema",
      "Espaço gourmet",
      "Espaço mulher",
      "Lan house",
      "Lavanderia",
      "Pergolado",
      "Pet Care",
      "Piscina",
      "Piscina com raia",
      "Piscina infantil",
      "Pista de skate",
      "Playground",
      "Praça",
      "Quadra de squash",
      "Quadra de tênis",
      "Quadra poliesportiva",
      "Sala de jogos",
      "Salão de festas",
      "Sauna",
      "Solarium",
      "Spa/Jacuzzi",
      "Vôlei de areia",
    ],
  };

  componentDidMount() {
    if (this.props.isAuthenticated) this.props.onInit({ lat: 0, lng: 0 });

    this.props.onFetchAdvertisements("/");
    this.props.onFetchReleases("", this.state.cidade);
  }

  handleReset = () => {
    this.setState({ loadingAddress: false, position: null });
  };

  resetImovel = () => {
    this.imovelSelecionado = undefined;
    this.setState({
      loadingAddress: false,
      position: null,
      imovel: null,
      mostrarPainel: false,
      mostrarQuadrinha: false,
      address: "",
    });
  };

  resetQuadrinha = () => {
    this.setState({ quadrinha: null, mostrarQuadrinha: false });
  };

  onChange = (_address) => {
    this.setState({ loadingAddress: false });
  };

  onPlaceSelected = (address) => {
    this.addressSearch = true;

    let newAddress = address.value;
    console.log(address);

    if (address.city !== undefined) {
      api
        .get(
          `/properties/search/address?city=${address.city}&address=${address.label}`
        )
        .then((res) => {
          if (!Array.isArray(res.data)) {
            newAddress = res.data;
            this.setState({ loadingAddress: true, imovel: newAddress });
          } else {
            for (const element of res.data) {
              if (element._id === address.value._id) {
                newAddress = element;
                break;
              }
            }

            this.imovelSelecionado = newAddress;
            this.setState({
              loadingAddress: true,
              imovel: newAddress,
              mostrarPainel: true,
            });
          }
        })
        .catch((err) => {});
    } else {
      this.imovelSelecionado = newAddress;
    }

    if (this.props.isAuthenticated) {
      this.props.onInit({ lat: 0, lng: 0 });
    }

    this.setState({
      loadingAddress: true,
      imovel: newAddress,
      mostrarPainel: true,
      scroll: true,
    });
  };

  onQuadrinhaSelected = (property) => {
    this.setState({ quadrinha: property, mostrarQuadrinha: true });
  };

  redirectToSignUp = () => {
    window.location.href = "/signup/";
  };

  redirectToSignature = () => {
    window.location.href = "/signup/";
  };

  buildURL = ({ loc = "", value = this.state.value }) => {
    let url = `/posts/search?post_type=empreendimento&cidade=${this.state.cidade}&tipo=${this.state.tipo}&elevadores=${this.state.elevadores}&lazer=${this.state.lazer}&page=${this.state.page}&itensPerPage=${this.state.itensPerPage}&created_from=&location=${loc}`;
    if (value) url = url + "&post_title=" + value;
    return url;
  };

  find = (payload = {}) => {
    const url = this.buildURL(payload);

    api
      .get(url)
      .then((res) => {
        this.setState({
          postsSearch: res.data.posts,
          count: res.data.count,
        });
      })
      .catch((err) => {});
  };

  handleChange = (event) => {
    const name = event.target.name;
    const city = event.target.value;

    window.localStorage.setItem("city", city);
    this.setState({
      ...this.state,
      [name]: city,
    });

    this.props.onFetchReleases("", city);
  };

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.state.options);
      }, 10000);
    });

  onInputChange = async (e) => {
    let options = [];
    if (e !== this.state.value && e) {
      const url = this.buildURL({ value: e });
      await api
        .get(url)
        .then((res) => {
          for (let i = 0; i < res.data.posts.length; i++) {
            options.push({ value: res.data.posts[i], label: res.data.posts[i].post_title });
          }
          this.setState({
            show: true,
            options: options,
            value: e,
            showList: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return options;
  };

  onInputSubmit = async (e) => {
    let options = [];
    this.setState({ show: false, options: [] });

    if (typeof e === "string") {
      this.find({ value: e });
    }

    return options;
  };

  handlePageChange = (event, page) => {
    this.setState({ page: page, doFind: true });
  };

  itsEmpty = () => {
    this.setState({ postsSelected: []})
  }

  doneFind = () => {
    this.find({});
    this.setState({ doFind: false });
  };

  handleMapVision = () => {
    const newMapVision = !this.state.mapVision;
    if (!newMapVision) {
      this.setState({
        postsSearch: null,
      });
      this.find({});
    }

    this.setState({
      mapVision: newMapVision,
    });
  };

  render() {
    let favorite = localStorage.getItem("favorite");
    let noRedirect = localStorage.getItem("no_redirect");

    if (noRedirect !== "sim") {
      if (this.props.isAuthenticated && favorite) {
        localStorage.removeItem("favorite");
        window.location.href = "/meusimoveis/edicao/" + favorite;
      }
    }

    let { isAuthenticated } = this.props;
    let { imovel } = this.state;

    if (imovel !== null && imovel.property_address !== undefined) {
      console.log("imovel.property_venal_value", imovel);

      if (isAuthenticated) {
        if (isAssinaturaValida()) {
          let favorito = this.isFavorito(imovel._id);

          if (favorito) {
            favorito = favorito.favorite;
          }
        }
      }
    }

    const onLoadPolygonCoordinates = (coordenadas, location) => {
      const [lngne, latne, lngsw, latsw] = location;
      if (lngne && latne && lngsw && latsw) {
        const location = [lngne, latne, lngsw, latsw].join(",");
        this.find({ loc: location });
        this.props.onLoadSuccess({
          lngne: lngne,
          latne: latne,
          lngsw: lngsw,
          latsw: latsw,
          coordenadas: coordenadas,
        });
      }
    };

    return (
      <>
        <br />
        {/* <div
          style={{
            maxWidth: "728px",
            height: "90px",
            borderStyle: "solid",
            borderColor: "#dcdcdc",
            margin: "auto",
          }}
        >
          <AdvertisementImages
            advertisements={this.props.advertisements["top"]}
            width="728px"
            height="90px"
          />
        </div> */}
        <br />
        {this.state.doFind && this.doneFind()}
        <Search
          tipo={this.state.tipo}
          cidade={this.state.cidade}
          lazer={this.state.lazer}
          elevadores={this.state.elevadores}
          itensDeLazer={this.state.itensDeLazer}
          itensDeEmpreendimentos={this.state.itensDeEmpreendimentos}
          handleChange={this.handleChange}
          find={this.find}
        />
        {this.state.postsSearch && Array.isArray(this.state.postsSearch) && (
          <div style={{ backgroundColor: "#ededed", padding: "30px" }}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#687700",
                fontSize: "30px",
              }}
            >
              Resultados da pesquisa
            </span>
            <br />
            <SearchPosts
              loadDefault={() => this.find({ value: "" })}
              itsEmpty={() => {}}
              selected={this.state.posts}
              clickAuto={this.onInputChange}
              clickSubmit={this.onInputSubmit}
              onPlaceSelected={this.onInputSubmit}
              load={this.promiseOptions}
            />
            <FormControlLabel
              style={{ display: "flex", justifyContent: "center" }}
              control={
                <Switch
                  checked={this.state.mapVision}
                  onChange={this.handleMapVision}
                  color="primary"
                />
              }
              label="Visão Mapa"
            />
            {this.state.mapVision ? (
              <div>
                <BuscaMapaImoveis
                  markerColor={markerColor}
                  imoveisWithinPolygon={this.state.postsSearch}
                  onLoadPolygonCoordinates={onLoadPolygonCoordinates}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                  <font style={{backgroundColor: markerColor['Edifícios Comerciais']}}>Edifícios Comerciais</font>
                  <font style={{backgroundColor: markerColor['Edifícios Residenciais']}}>Edifícios Residenciais</font>
                  <font style={{backgroundColor: markerColor['Loteamentos Comerciais / Industriais']}}>Loteamentos Comerciais / Industriais</font>
                  <font style={{backgroundColor: markerColor['Loteamentos Fechados']}}>Loteamentos Fechados</font>
                </div>
              </div>
            ) : (
              <div
                className={this.classes.root}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid container spacing={5} style={{ maxWidth: "1200px" }}>
                  {this.state.postsSearch.map((p) => (
                    <InformationCard
                      id={p._id}
                      post_title={p.post_title}
                      src={
                        (p.metas &&
                          p.metas.thumbnail &&
                          serveImage(p.metas.thumbnail.url)) ||
                        ImageDefault
                      }
                      tipo={p.metas.tipo}
                      bairro={p.metas.bairro}
                      cidade={p.metas.cidade}
                      created_from={
                        p.created_from !== undefined ? p.created_from : ""
                      }
                    />
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Pagination
                      count={this.state.count}
                      page={this.state.page}
                      shape="rounded"
                      onChange={this.handlePageChange}
                    />
                  </div>
                </Grid>
              </div>
            )}
          </div>
        )}
        <br />
        {/* <div
          style={{
            maxWidth: "728px",
            height: "300px",
            borderStyle: "solid",
            borderColor: "#dcdcdc",
            margin: "auto",
          }}
        >
          <AdvertisementImages
            advertisements={this.props.advertisements["middle"]}
            width="728px"
            height="300px"
          />
        </div> */}
        <br />
        <div style={{ backgroundColor: "#ededed", padding: "30px" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#687700",
              fontSize: "30px",
            }}
          >
            Destaque
          </span>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#aac401",
              fontSize: "30px",
            }}
          >
            Lançamentos
          </span>
          <br />
          <div
            className={this.classes.root}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid container spacing={5} style={{ maxWidth: "1200px" }}>
              {this.props.posts &&
                this.props.posts.map((p) => (
                  <InformationCard
                    id={p._id}
                    post_title={p.post_title}
                    src={
                      (p.metas &&
                        p.metas.thumbnail &&
                        serveImage(p.metas.thumbnail.url)) ||
                      ImageDefault
                    }
                    tipo={p.metas.tipo}
                    bairro={p.metas.bairro}
                    cidade={p.metas.cidade}
                  />
                ))}
            </Grid>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  adicionarFavorito = (id, bok, type, idPredio) => {
    this.setState({
      mostrarPainel: true,
      imovel: this.imovelSelecionado,
      loadingFavorito: true,
    });
    this.props.criarFavorito(id, false);
    if (!bok) {
      localStorage.setItem("no_redirect", "sim");
    } else {
      localStorage.removeItem("no_redirect");
    }
    this.setState({ loadingFavorito: false });
  };

  isFavorito = (id) => {
    return (
      this.props.favoritos &&
      this.props.favoritos.find(
        (element) =>
          element.property !== undefined && element.property._id === id
      )
    );
  };
}

const mapStateToProps = (state) => {
  return {
    imoveisproximos: state.localizacao.imoveisproximos,
    imoveisproximospredios: state.localizacao.imoveisproximospredios,
    loading: state.localizacao.loading,
    loadingimoveis: state.imoveis.loading,
    center: state.localizacao.center,
    buscou: state.localizacao.buscou,
    firstTime: state.localizacao.firstTime,
    centraliza: state.localizacao.centraliza,
    coordenadas: state.localizacao.coordenadas,
    error_geo: state.localizacao.error_geo,
    favoritos: state.localizacao.favoritos,
    favoritosPredios: state.localizacao.favoritosPredios,
    id_redirect_favorito: state.imoveis.id_redirect_favorito,
    newfavorito: state.localizacao.newfavorito,
    isAuthenticated: state.auth.token !== null,
    advertisements: state.advertise.advertisements,
    posts: state.imoveis.posts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReleases: (page, city) =>
      dispatch(actions.fetchReleases(page, city)),
    onSetBuscou: (buscou) => dispatch(actions.onSetBuscou(buscou)),
    onFetchAdvertisements: (page) =>
      dispatch(actions.fetchAdvertisements(page)),
    onLoadSuccess: (payload) => dispatch(actions.loadSuccess(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuscaVenal);
