import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import SimNao from './SimNao/SimNao';

import './Input.css';

const input = ( props ) => {
    let inputElement = null;
    const inputClasses = ["InputElement"];

    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push("Invalid");
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            />;
            break;
        case ( 'textarea' ):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        case ( 'select' ):
            inputElement = (
                <Select
                    value={props.value ? (props.value.name || props.value.slug || props.value || '') : ''}
                    onChange={props.changed}
                    required={props.required}
                    className={inputClasses.join(' ')}
                    renderValue={value => value.name || value}
                >
                    {props.elementConfig.options && props.elementConfig.options.map(option => (
                        <MenuItem key={option.value || option.slug} value={option.value || option}>
                            <ListItemText primary={option.displayValue || option.name || option} />
                        </MenuItem>
                    ))}
                </Select>
            );
            break;
        case ( 'simnao' ):
            inputElement = (
                <SimNao
                    {...props}
                    classes={inputClasses.join(' ')}
                    elementConfig= {props.elementConfig} />
            );
            break;
        case 'checkbox':
            inputElement = (
                <input
                    type="checkbox"
                    checked={props.value}
                    onChange={props.changed}
                />
            )
            break;
        case 'multiselect':
            debugger
            inputElement = (
                <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
                        <Select
                            defaultValue=""
                            className={inputClasses.join(' ')}
                            multiple
                            value={props.value && props.value.length > 0 ? props.value : []}
                            onChange={props.changed}
                            label={props.label}
                            inputProps={{
                                name: props.id,
                                id: 'outlined-age-native-simple',
                            }}
                            renderValue={selected => selected.map(s => s.name).join(', ')}
                        >
                            {props.elementConfig.options && props.elementConfig.options.map(option => (
                                <MenuItem key={option.slug} value={option}>
                                    <Checkbox checked={props.value && props.value.map(v => v.slug).includes(option.slug)} />
                                    <ListItemText primary={option.name} />
                                </MenuItem>)
                            )}
                        </Select>
                </FormControl>
            )
            break;
        // case ( 'superselect' ):
        //     return (
        //         <fieldset className="Superselect">
        //             <legend>{props.label}</legend>
        //             {props.elementConfig.options.map(option => (
        //                 <div className="Superselectitem">
        //                     <div className="Input">
        //                         <label className="Label">{option.displayValue}</label>
        //                         <input
        //                             className={inputClasses.join(' ')}
        //                             {...props.elementConfig}
        //                             onChange={props.changed(option.value)} />
        //                     </div>
        //                 </div>
        //             ))}
        //         </fieldset>
        //     );
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
    }

    if (props.elementType === "simnao" || props.elementType === "checkbox") {
        return (
            <div className={"Input simnao"}>
                {inputElement}
                <label className="Label">{props.label}</label>
            </div>
        );
    } else {
        return (
            <div style={props.style}>
                <label className="Label" >{props.label}</label>
                {inputElement}
            </div>
        );
    }

};

export default input;
