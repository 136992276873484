import * as actionsNames from "./actionsNames";
import api from "../../services/api";
import configToken from "../../utils/configToken";

import {onAddFavorito} from './localizacao';

const sendMessageStart = () => {
  return {
    type: actionsNames.SEND_CLIENT_MESSAGE_START,
  };
};

const sendMessageSuccess = response => {
  return {
    type: actionsNames.SEND_CLIENT_MESSAGE_SUCCESS,
    message: response.message
  };
};

const sendMessageFailure = error => {
  return {
    type: actionsNames.SEND_CLIENT_MESSAGE_FAILURE,
    error: error
  };
};

export const sendMessage = (clientData) => {
  return dispatch => {
    dispatch(sendMessageStart())
    api
      .post('/users/message', clientData)
      .then(response => {
        dispatch(sendMessageSuccess( { message: response.data.message } ));
      })
      .catch(err => {
        if(err.response){
          dispatch(sendMessageFailure(err.response.data));
        }
      });
  };
};

export const criarFavoritoSuccess = (favorito, editar) => {
  return {
    type: actionsNames.CRIAR_FAVORITO_SUCCESS,
    favorito: favorito,
    editar: editar
  };
};

const criarFavoritoFail = error => {
  return {
    type: actionsNames.CRIAR_FAVORITO_FAIL,
    error: error
  };
};

const criarFavoritoStart = () => {
  return {
    type: actionsNames.CRIAR_FAVORITO_START
  };
};

export const criarFavorito = (imovel_id, editar) => {
  return dispatch => {
    dispatch(criarFavoritoStart());
    api
      .post("/favorites/create", { propertyId: imovel_id }, configToken())
      .then(response => {
        if (response.data.message === "Favorito já cadastrado") {
          alert("Imóvel já favoritado!");
          dispatch(criarFavoritoFail({message:"Imóvel já favoritado!"}));
        } else {
          if(editar){
            dispatch(criarFavoritoSuccess(response.data.favorite, editar));
          }else{
            dispatch(onAddFavorito());
            localStorage.removeItem('favorite')
            localStorage.setItem('favorite', response.data.favorite._id)
          }
        }
      })
      .catch(error => {
        dispatch(criarFavoritoFail(error));
      });
  };
};

const salvarFavoritoSuccess = favorito => {
  return {
    type: actionsNames.SALVAR_FAVORITO_SUCCESS,
    favorito: favorito
  };
};

const salvarFavoritoFail = error => {
  return {
    type: actionsNames.SALVAR_FAVORITO_FAIL,
    error: error
  };
};

const salvarFavoritoStart = () => {
  return {
    type: actionsNames.SALVAR_FAVORITO_START
  };
};

export const salvarFavorito = (id, favoritoData) => {
  return dispatch => {
    dispatch(salvarFavoritoStart());
    api
      .patch("/favorites/" + id, favoritoData, {
        headers: {
          ...configToken().headers,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(response => {
        alert("Favorito salvo com sucesso!");
        dispatch(salvarFavoritoSuccess(response.data));
      })
      .catch(error => {
        dispatch(salvarFavoritoFail(error));
      });
  };
};

const fetchFavoritosSuccess = data => {
  return {
    type: actionsNames.FETCH_FAVORITOS_SUCCESS,
    data: data
  };
};

const fetchFavoritosListSuccess = data => {
  return {
    type: actionsNames.FETCH_FAVORITOS_ALL_SUCCESS,
    data: data
  };
};

const fetchFavoritosFail = error => {
  return {
    type: actionsNames.FETCH_FAVORITOS_FAIL,
    error: error
  };
};

const fetchFavoritosStart = () => {
  return {
    type: actionsNames.FETCH_FAVORITOS_START
  };
};

const fetchPostsStart = () => {
  return {
    type: actionsNames.FETCH_POSTS_START
  };
};

const fetchPostsSuccess = data => {
  return {
    type: actionsNames.FETCH_POSTS_SUCCESS,
    data: data
  };
};

const fetchPostsFail = error => {
  return {
    type: actionsNames.FETCH_POSTS_FAIL,
    error: error
  };
};

export const fetchReleases = (page, city) => {
  return dispatch => {
    dispatch(fetchPostsStart());
    api
      .get(`/posts/releases?page=${page}&city=${city}`)
      .then(res => {
        dispatch(fetchPostsSuccess(res.data));
      })
      .catch(res => {
        dispatch(fetchPostsFail(res.data));
      })
  };
};

export const savePost = (id, formData) => {
  return dispatch => {
    dispatch(savePostStart());
    api
      .patch(`/posts/admin/save/${id}`, formData, {
        headers: {
          ...configToken().headers,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(res => {
        dispatch(savePostSuccess(res.data));
        window.location.reload();
      })
      .catch(res => {
        dispatch(savePostFail(res.data));
      })
  };
};

const savePostStart = () => {
  return {
    type: actionsNames.SAVE_POST_START
  };
};

const savePostSuccess = data => {
  return {
    type: actionsNames.SAVE_POST_SUCCESS,
    data: data
  };
};

const savePostFail = error => {
  return {
    type: actionsNames.SAVE_POST_FAIL,
    error: error
  };
};

export const deletePost = id => {
  return dispatch => {
    dispatch(deletePostStart());
    api
      .delete(`/posts/admin/delete/${id}`, configToken())
      .then(res => {
        dispatch(deletePostSuccess(res.data));
      })
      .catch(res => {
        dispatch(deletePostFail(res.data));
      })
  };
};

const deletePostStart = () => {
  return {
    type: actionsNames.DELETE_POST_START
  };
};

const deletePostSuccess = data => {
  return {
    type: actionsNames.DELETE_POST_SUCCESS,
    data: data
  };
};

const deletePostFail = error => {
  return {
    type: actionsNames.DELETE_POST_FAIL,
    error: error
  };
};

export const fetchPosts = ({ adminUrl = '', postType = 'empreendimento', partner = '', page = 1, itensPerPage = 12, city = '', postTitle = '' }) => {
  let url = `/posts/${adminUrl}search?post_type=${postType}&page=${page}&itensPerPage=${itensPerPage}&partner=${partner}&cidade=${city}`;
  if (postTitle) url = url + '&post_title=' + postTitle
  return dispatch => {
    dispatch(fetchPostsStart());
    api
      .get(url, configToken())
      .then(res => {
        dispatch(fetchPostsSuccess(res.data));
      })
      .catch(res => {
        dispatch(fetchPostsFail(res.data));
      })
  }
};

export const fetchFavoritos = () => {
  return dispatch => {
    dispatch(fetchFavoritosStart());
    api
      .get("/favorites/all/parsed", configToken())
      .then(res => {
        let fetchedFavoritos = [];
        let fetchedFavoritosPredios = [];
        Object.entries(res.data.parsedFavorites).forEach(entry => {
          let key = entry[0];
          let value = entry[1];
          if (value.length > 1) {
            fetchedFavoritosPredios.push({
              id: key,
              ...value
            });
          } else {
            fetchedFavoritos.push({
              id: key,
              ...value[0]
            });
          }
        });

        dispatch(
          fetchFavoritosSuccess({
            favoritos: fetchedFavoritos,
            favoritosPredios: fetchedFavoritosPredios
          })
        );
      })
      .catch(err => {
        dispatch(fetchFavoritosFail(err));
      });
  };
};

export const fetchFavoritosList = () => {
  return dispatch => {
    dispatch(fetchFavoritosStart());
    api
      .get("/favorites/all", configToken())
      .then(res => {
        dispatch(
          fetchFavoritosListSuccess({ favoritosAll: res.data.favorites })
        );
      })
      .catch(err => {
        dispatch(fetchFavoritosFail(err));
      });
  };
};

const deleteFavoritoSuccess = (message, id_favorito) => {
  return {
    type: actionsNames.DELETE_FAVORITO_SUCCESS,
    message: message,
    _id: id_favorito
  };
};

const deleteFavoritoFail = error => {
  return {
    type: actionsNames.DELETE_FAVORITO_FAIL,
    error: error
  };
};

const deleteFavoritoStart = () => {
  return {
    type: actionsNames.DELETE_FAVORITO_START
  };
};

export const removeFavorito = id_favorito => {
  return dispatch => {
    dispatch(deleteFavoritoStart());
    api
      .delete("/favorites/" + id_favorito, configToken())
      .then(res => {
        if (res.status === 200) {
          alert("Removido com sucesso!");
          dispatch(deleteFavoritoSuccess("Excluído com sucesso!", id_favorito));
        } else {
          dispatch(deleteFavoritoSuccess(null, null));
        }
      })
      .catch(err => {
        dispatch(deleteFavoritoFail(err));
      });
  };
};

const deleteImagemFail = error => {
  return {
    type: actionsNames.DELETE_IMAGE_FAVORITO_FAIL,
    error: error
  };
};

const deleteImagemStart = () => {
  return {
    type: actionsNames.DELETE_IMAGE_FAVORITO_START
  };
};

const deleteImagemSuccess = message => {
  return {
    type: actionsNames.DELETE_IMAGE_FAVORITO_SUCCESS,
    message: message
  };
};

export const removeFotoFavorito = chaves => {
  return dispatch => {
    dispatch(deleteImagemStart);
    api
      .patch("/favorites/image/del/", { ...configToken(), data: chaves })
      .then(response => {
        dispatch(deleteImagemSuccess(response.data.message));
      })
      .catch(err => {
        if (err.response) {
          dispatch(deleteImagemFail(err.response.data));
        }
      });
  };
};

const exportFavoriteStart = () => {
  return {
    type: actionsNames.EXPORT_FAVORITO_START
  };
};

const exportFavoriteSuccess = message => {
  return {
    type: actionsNames.EXPORT_FAVORITO_SUCCESS,
    message: message
  };
};

const exportFavoriteFail = error => {
  return {
    type: actionsNames.EXPORT_FAVORITO_FAIL,
    error: error
  };
};

export const exportFavorite = favorito_id => {
  return dispatch => {
    dispatch(exportFavoriteStart);
    let token = localStorage.getItem("token");
    let params = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob"
    };
    api
      .get("/report/" + favorito_id, params)
      .then(response => {
        const file = new Blob([response.data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
        dispatch(exportFavoriteSuccess("Exportado com sucesso!"));
      })
      .catch(err => {
        if (err.response) {
          dispatch(exportFavoriteFail(err.response.data));
        }
      });
  };
};

const setFavorito = favorito => {
  return {
    type: actionsNames.SET_FAVORITO,
    favorito: favorito
  };
};

export const onSetFavorito = favorito => {
  return dispatch => {
    dispatch(setFavorito(favorito));
  };
};

const setFavoritos = favoritos => {
  return {
    type: actionsNames.SET_FAVORITO,
    favoritos: favoritos
  };
};

export const onSetFavoritos = favoritos => {
  return dispatch => {
    dispatch(setFavoritos(favoritos));
  };
};

const loadFavorito = favorito => {
  return {
    type: actionsNames.LOAD_FAVORITO,
    favorito: favorito,
    property: favorito.property
  };
};

export const onLoadFavorito = favorito_id => {
  return dispatch => {
    dispatch(fetchFavoritosStart());
    api
      .get("/favorites/all", configToken())
      .then(res => {
        const favoritos = res.data.favorites;
        const favorito = favoritos.find(
          element => element.favorite._id === favorito_id
        );
        dispatch(loadFavorito(favorito));
      })
      .catch(err => {
        dispatch(fetchFavoritosFail(err));
      });
  };
};

export const changeFavorito = (newvalue, controlname) => {
  return {
    type: actionsNames.CHANGE_FAVORITO,
    newvalue: newvalue,
    controlname: controlname
  };
};

export const changeInteressado = (newval, id, field) => {
  return {
    type: actionsNames.CHANGE_INTERESSADO,
    data: {newval, id, field},
  };
}

export const addInteressado = () => {
  return {
    type: actionsNames.ADD_INTERESSADO,
  };
}

export const removeInteressado = (id) => {
  return {
    type: actionsNames.REMOVE_INTERESSADO,
    id
  };
}

export const filterFavoritos = (field, newvalue) => {
  return {
    type: actionsNames.FILTER_FAVORITOS,
    data: {field, newvalue}
  };
}
