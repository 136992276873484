import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Box,
  Input,
  InputLabel,
  Chip,
  DialogActions,
  ListItemText,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import QuantSelect from "../QuantSelect/QuantSelect";
import GeneralSelect from "../GeneralSelect/GeneralSelect";

const useStyles = makeStyles((theme) => ({
formControl: {
  display: "flex",
  justifyContent: "space-around"
},

  chips: {
    display: "flex",
    flexWrap: "wrap",
    margin: "2px",
  },
}));

const AdvancedFilter = ({ filterOptions, onChangeCity, onSearch }) => {
  const classes = useStyles();
  const [filterState, setFilterState] = useState({
    city: "",
    neighborhood: "",
    type: "",
    roomsMin: "",
    roomsMax: "",
    suitesMin: "",
    suitesMax: "",
    garageMin: "",
    garageMax: "",
    priceMin: "",
    priceMax: "",
    areaMax: "",
    areaMin: "",
    livRoomsMax: "",
    livRoomsMin: "",
    bathroomsMax: "",
    bathroomsMin: "",
    lazer: [],
  });

  useEffect(() => {
    // Perform the search whenever filterState changes
    const performSearch = () => {
      // Collect and filter the form data
      const formData = {
        cidade: filterState.city,
        tipo: filterState.type,
        lazer: filterState.lazer.join(","),
        bairro: filterState.neighborhood,
        quartosMin: filterState.roomsMin,
        quartosMax: filterState.roomsMax,
        banheirosMin: filterState.bathroomsMin,
        banheirosMax: filterState.bathroomsMax,
        garagensMin: filterState.garageMin,
        garagensMax: filterState.garageMax,
        suitesMin: filterState.suitesMin,
        suitesMax: filterState.suitesMax,
        salasMin: filterState.livRoomsMin,
        salasMax: filterState.livRoomsMax,
        precoMin: filterState.priceMin,
        precoMax: filterState.priceMax,
        areaMin: filterState.areaMin,
        areaMax: filterState.areaMax,
        post_type: "empreendimento",
        created_from: "xml-disabled",
      };
      // Call the onSearch function with the form data
      onSearch(formData);
    };

    performSearch();
  }, [filterState, onSearch]);

  const handleClear = () => {
    setFilterState({
      city: "",
      neighborhood: "",
      type: "",
      roomsMin: "",
      roomsMax: "",
      suitesMin: "",
      suitesMax: "",
      garageMin: "",
      garageMax: "",
      priceMin: "",
      priceMax: "",
      areaMax: "",
      areaMin: "",
      livRoomsMax: "",
      livRoomsMin: "",
      bathroomsMax: "",
      bathroomsMin: "",
      lazer: [],
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
    getContentAnchorEl: () => null,
  };

  const itensDeEmpreendimentos = [
    "Casa de Condominio",
    "Casa",
    "Apartamento",
    "Imóvel Comercial",
    "Terreno Comercial",
    "Terreno",
    "Fazenda / Sítio",
    "Loja",
    "Chácara",
    "Cobertura",
    "Imóvel Industrial",
    "Kitnet",
    "Escritório Comercial"
  ];

  return (
    <div>
      <DialogTitle>Filtro</DialogTitle>
      <DialogContent>
        {/* City */}
        <GeneralSelect
                value={filterState.city}
                nameValue="cidade"
                updateWidth="80%"
                onChange={(e) => {
                  setFilterState({ ...filterState, city: e.target.value });
                  onChangeCity(e);
                }}
                optionsArray={filterOptions && filterOptions.cidades}
              />
  
        {/* Neighborhood */}
        {filterOptions && filterOptions.neighborhoods && (
          <FormControl className={classes.formControl}>
            <InputLabel>Bairro</InputLabel>
            <Select
              value={filterState.neighborhood}
              onChange={(e) =>
                setFilterState({ ...filterState, neighborhood: e.target.value })
              }
              input={<Input />}
              MenuProps={MenuProps}
            >
              {filterOptions.neighborhoods.map((neighborhood) => (
                <MenuItem key={neighborhood} value={neighborhood}>
                  {neighborhood}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Property Type */}
        <GeneralSelect
                value={filterState.type}
                nameValue="tipo"
                updateWidth="80%"
                onChange={(e) => {
                  setFilterState({ ...filterState, type: e.target.value });
                }}
                optionsArray={itensDeEmpreendimentos}
              />
       
        <Box mt={2}> {/* Add margin-top to create space */}
          {/* Number of Rooms */}
            <QuantSelect
              minValue={filterState.roomsMin}
              maxValue={filterState.roomsMax}
              label="Quartos"
              nameMinLabel="roomsMin"
              nameMaxLabel="roomsMax"
              onChange={(e) =>
                setFilterState({ ...filterState, [e.target.id]: e.target.value })
              }
            />
          </Box>
        
          {/* Number of Suites */}
            <QuantSelect
              minValue={filterState.suitesMin}
              maxValue={filterState.suitesMax}
              label="Suítes"
              nameMinLabel="suitesMin"
              nameMaxLabel="suitesMax"
              onChange={(e) =>
                setFilterState({ ...filterState, [e.target.id]: e.target.value })
              }
            />
        
          {/* Number of Garages */}
            <QuantSelect
              minValue={filterState.garageMin}
              maxValue={filterState.garageMax}
              label="Garagens"
              nameMinLabel="garageMin"
              nameMaxLabel="garageMax"
              onChange={(e) =>
                setFilterState({ ...filterState, [e.target.id]: e.target.value })
              }
            />
      
          {/* Number of Bathrooms */}
            <QuantSelect
              minValue={filterState.bathroomsMin}
              maxValue={filterState.bathroomsMax}
              label="Banheiros"
              nameMinLabel="bathroomsMin"
              nameMaxLabel="bathroomsMax"
              onChange={(e) =>
                setFilterState({ ...filterState, [e.target.id]: e.target.value })
              }
            />
     
          {/* Number of Living Rooms */}
            <QuantSelect
              minValue={filterState.livRoomsMin}
              maxValue={filterState.livRoomsMax}
              label="Salas"
              nameMinLabel="livRoomsMin"
              nameMaxLabel="livRoomsMax"
              onChange={(e) =>
                setFilterState({ ...filterState, [e.target.id]: e.target.value })
              }
            />
 
        <Box display="flex" alignItems="flex-end">
          <Box>
            <h5 style={{ margin: "0 0 8px 0" }}>Área útil de</h5>
            <TextField
              id="areaMin"
              label="m²"
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setFilterState({ ...filterState, areaMin: e.target.value });
              }}
              value={filterState.areaMin}
            />
          </Box>
          <Box ml={2}>
            <h5 style={{ margin: "0 0 8px 0" }}>até</h5>
            <TextField
              id="areaMax"
              label="m²"
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setFilterState({ ...filterState, areaMax: e.target.value });
              }}
              value={filterState.areaMax}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="flex-end">
          <Box>
            <h5 style={{ margin: "8px 0 8px 0" }}>Preço Venda de</h5>
            <TextField
              id="priceMin"
              label="R$"
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setFilterState({ ...filterState, priceMin: e.target.value });
              }}
              value={filterState.priceMin}
            />
          </Box>
          <Box ml={2}>
            <h5 style={{ margin: "8px 0 8px 0" }}>até</h5>
            <TextField
              id="priceMax"
              label="R$"
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setFilterState({ ...filterState, priceMax: e.target.value });
              }}
              value={filterState.priceMax}
            />
            </Box>
        </Box>

        {/* Descrição do imóvel */}
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">
            Descrição do imóvel
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            multiple
            value={filterState.lazer}
            onChange={(e) => {
              setFilterState({ ...filterState, lazer: e.target.value });
            }}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {filterOptions &&
              filterOptions.lazer &&
              filterOptions.lazer.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    color="secondary"
                    checked={filterState.lazer.indexOf(name) > -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        </DialogContent>
      <DialogActions>
        <Button onClick={handleClear} color="secondary">
          Limpar
        </Button>
      </DialogActions>
    </div>
  );
};

export default AdvancedFilter;